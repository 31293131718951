import React from 'react';

import Transition from 'components/Transition';

interface WrapPageElementProps {
  element: JSX.Element;
  props: any;
}

const wrapPageElement: React.SFC<WrapPageElementProps> = ({
  element,
  props,
}) => <Transition {...props}>{element}</Transition>;

export default wrapPageElement;
