import React from 'react';
import AppProvider from 'contexts/AppProvider';

import wrapPageElementWithTransition from 'helpers/wrapPageElement.tsx';

// React Context in Browser
// eslint-disable-next-line
export const wrapRootElement = ({ element }) => {
  return <AppProvider>{element}</AppProvider>;
};

// Page Transitions
export const wrapPageElement = wrapPageElementWithTransition;

// IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
// https://github.com/timhagn/gatsby-background-image/tree/master/packages/gatsby-background-image#important
// eslint-disable-next-line
export const onClientEntry = () => {
  if (typeof window.IntersectionObserver === 'undefined') {
    import('intersection-observer');
  }
};
